<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      @click="showModal"
      variant="outline-primary"
    >
      {{ this.buttonText ? this.buttonText : "Select Media" }}
    </b-button>
    <b-modal
      id="modal-center"
      ref="media-select"
      scrollable
      size="xl"
      @hidden="modalClosed"
      title="Media Selector"
      ok-only
      ok-title="Select"
    >
      <b-card-text>
        <vue-select-image
          w="100px"
          h="100px"
          :dataImages="images"
          :is-multiple="isMultiple"
          @onselectimage="onSelectImage"
          @onselectmultipleimage="onSelectMultipleImage"
          :selectedImages="isMultiple ? selectedMedia : [selectedSingleMedia]"
        >
        </vue-select-image>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BCardText,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import VueSelectImage from "vue-select-image";
import "vue-select-image/dist/vue-select-image.css";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BCardText,
    BRow,
    BCol,
    BImg,
    VueSelectImage,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    isMultiple: {
      type: Boolean,
      default: true,
    },
    selectedMediaFiles: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedSingleMediaFile: {
      type: Object,
      default() {
        return {};
      },
    },
    params: {
      type: Object,
      default: null,
    },
    buttonText: {
      type: String,
    },
  },
  data() {
    return {
      images: [],
      selectedMedia:
        this.selectedMediaFiles.length > 0 ? this.selectedMediaFiles : [],
      selectedSingleMedia:
        this.selectedSingleMediaFile && this.selectedSingleMediaFile.src
          ? this.selectedSingleMediaFile
          : {},
    };
  },
  methods: {
    showModal() {
      this.$refs["media-select"].show();
    },
    modalClosed() {
      if (this.isMultiple) {
        if (this.selectedMedia.length > 0) {
          let multipleMediaObject = {
            status: true,
            media: this.selectedMedia,
          };
          if (this.params) {
            multipleMediaObject.params = this.params;
          }
          this.$emit("mediaSelected", multipleMediaObject);
        } else {
          this.$emit("mediaSelected", { status: false });
        }
      } else {
        if (this.selectedSingleMedia.src) {
          let singleMediaObject = {
            status: true,
            media: this.selectedSingleMedia,
          };
          if (this.params) {
            singleMediaObject.params = this.params;
          }
          this.$emit("mediaSelected", singleMediaObject);
        } else {
          this.$emit("mediaSelected", { status: false });
        }
      }
      this.getGallery();
    },
    getMediaType(media) {
      return (media.match(/[^\\\/]\.([^.\\\/]+)$/) || [null]).pop();
    },
    getGallery() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gallery/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          let images = [];
          for (let i = 0; i < response.data.length; i++) {
            const image = response.data[i];
            let type = this.getMediaType(image.url);
            if (type != "pdf") {
              images.push({
                id: image._id,
                src: image.url,
                key: image.key,
              });
            }
          }
          this.images = images;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onSelectMultipleImage(media) {
      this.selectedMedia = media;
    },
    onSelectImage(media) {
      this.selectedSingleMedia = media;
    },
  },
  created() {
    this.getGallery();
  },
};
</script>

<style>
</style>